import React, { useState } from 'react'
import styled from '@emotion/styled'
import { keyframes } from '@emotion/core'
import { Controller, Scene } from 'react-scrollmagic'
import ArrowIcon from '../../../images/arrow-white.svg'
import { CTA } from '../../Buttons/TransparentCTA'

const slideInRight = keyframes`
  to {
      transform: translateX(0);
      opacity: 1;
  }
`

const Container = styled('div')`
  background: linear-gradient(167.69deg, #601947 1.97%, #315567 97.39%);
  display: flex;
  width: 70%;
  max-width: 1000px;
  margin-left: auto;
  transform: translateX(1000px);
  padding: 34px 75px 30px 51px;
  justify-content: space-between;
  opacity: 0;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    width: 75%;
    padding: 17px 46px 30px 40px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    background: linear-gradient(149.18deg, #601947 1.97%, #315567 97.39%);
    width: calc(100% - 66px);
    flex-direction: column;
    padding: 37px 6px 30px 46px;
  }
`

const Text = styled('p')`
  font-family: ${props => props.theme.fonts.primaryMedium};
  color: #fff;
  font-size: 48px;
  line-height: 60px;
  margin: 0;
  flex-basis: 70%;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 22px;
    line-height: 30px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 18px;
    line-height: 21px;
    margin-bottom: 20px;
  }
`

const Section = styled('div')`
  margin-top: 130px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    margin-top: 63px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    margin-top: 52px;
  }

  .slideIn {
    animation: ${slideInRight} 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
`

const StyledLink = styled('a')`
  font-size: 24px;
  margin-top: auto;
  color: #fff;
  text-decoration: none;
  line-height: 1;
  font-weight: lighter;
  flex-shrink: 0;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 18px;
    line-height: 36px;
  }

  span {
    border-bottom: 1px solid #fff;
    margin-right: 10px;

    @media (max-width: ${props => props.theme.screenWidth.small}) {
      margin-right: 5px;
    }
  }
`

const CTAContainer = styled('div')`
  margin-top: auto;
  width: 240px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    width: 190px;
  }
`

const StyledCTA = styled(CTA)`
  span {
    font-family: ${props => props.theme.fonts.primaryMedium};
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    height: 40px;
    line-height: 40px;

    span {
      font-size: 14px;
      line-height: 16px;
    }
  }
`

export default function SlidingBanner({ text, ctaText, href, gatsbyLink }) {
  const [sceneEnabled, setSceneEnabled] = useState(true)

  return (
    <Section>
      <Controller>
        <Scene
          classToggle="slideIn"
          offset="-300px"
          // indicators={true}
          enabled={sceneEnabled}
        >
          {(progress, event) => {
            if (event.type === 'enter') {
              setSceneEnabled(false)
            }
            return (
              <Container>
                <Text>{text}</Text>
                {gatsbyLink ? (
                  <CTAContainer>
                    <StyledCTA to={href}>
                      <span>{ctaText}</span>
                    </StyledCTA>
                  </CTAContainer>
                ) : (
                  <StyledLink
                    href={href}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span>{ctaText}</span> <img src={ArrowIcon} alt="" />
                  </StyledLink>
                )}
              </Container>
            )
          }}
        </Scene>
      </Controller>
    </Section>
  )
}
