import React from 'react'
import LogosBanner from '../Shared/Banners/Logos'
import HeroWithSVG from './HeroWithSVG'
import IntroBlock from '../Shared/Blocks/IntroBlock'
import ClientTestimonials from '../Shared/ClientTestimonials/ClientTestimonials'
import WhatWeDo from './WhatWeDo'
import Line from '../Shared/Line/Line'
import SlidingBanner from './SlidingBanner'
import { testimonials, quotes } from '../Shared/data/client-testimonials'
import PillarBlock from './PillarBlocks'

export default function Services() {
  return (
    <React.Fragment>
      <HeroWithSVG />
      <IntroBlock />
      <WhatWeDo withLinks />
      <PillarBlock />
      <ClientTestimonials testimonials={testimonials} quotes={quotes} />
      <LogosBanner />
      <SlidingBanner />
      <Line />
    </React.Fragment>
  )
}
