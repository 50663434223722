import React from 'react'
import { pillarsData } from './data'
import Pillar from './Pillar'
import { useSpring, animated } from 'react-spring'
import VisibilitySensor from 'react-visibility-sensor'
import useWindowDimensions from '../../util/useWindowDimensions'

export default function PillarBlocks(props) {
  const initialBgColor = pillarsData[0].color

  const [springProps, set] = useSpring(() => ({
    config: { duration: 500 },
    backgroundColor: initialBgColor,
  }))
  const { height } = useWindowDimensions()

  return (
    <animated.div style={springProps}>
      {pillarsData.map((pillar, i) => {
        const { color } = pillar
        const [opacityProp, setOpacityProp] = useSpring(() => ({
          config: { duration: 500 },
          opacity: 0,
        }))

        function onChange(isVisible) {
          if (isVisible) {
            set({ backgroundColor: color })
            setOpacityProp({ opacity: 1 })
          } else {
            setOpacityProp({ opacity: 0 })
          }
        }

        return (
          <VisibilitySensor
            minTopValue={height / 2}
            partialVisibility
            onChange={onChange}
            key={i}
          >
            <animated.div style={opacityProp}>
              <Pillar {...props} data={pillar} />
            </animated.div>
          </VisibilitySensor>
        )
      })}
    </animated.div>
  )
}
