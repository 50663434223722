import React from 'react'
import styled from '@emotion/styled'
import SlidingBanner from '../Shared/SlidingBanner'

const Container = styled('div')`
  padding-bottom: 117px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding-bottom: 81px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    padding-bottom: 59px;
  }
`

export default function SlidingBannerSection() {
  return (
    <Container>
      <SlidingBanner
        href="/contact"
        gatsbyLink={true}
        text="Ready to take your product to the next level?"
        ctaText="Request a meeting"
      />
    </Container>
  )
}
