import cubicHealthLogo from '../../images/client-testimonials/cubic.svg'
import clickaiLogo from '../../images/client-testimonials/click-ai-logo.svg'
import actoLogo from '../../images/client-testimonials/acto.svg'
import FBImage from '../../images/services/freshbooker.jpg'
import LeagueImage from '../../images/services/league.jpg'
import OmstarsImage from '../../images/services/omstars.jpg'
import SessionzImage from '../../images/services/sessionz.jpg'
import MagnetImage from '../../images/services/magnet.jpg'
import FinaeoImage from '../../images/services/finaeo.jpg'
import WildbrainImage from '../../images/services/wildbrain.jpg'
import UnileverImage from '../../images/services/unilever.jpg'
import LanceImage from '../../images/services/lance.jpg'
import MercatusImage from '../../images/services/mercatus.jpg'
import NetsweeperImage from '../../images/services/netsweeper.jpg'
import MLSEImage from '../../images/services/mlse.jpg'
import YumbaImage from '../../images/services/yumba.jpg'

export const quotes = [
  {
    logo: {
      file: {
        url: cubicHealthLogo,
      },
      description: 'SmartTones Media Logo',
    },
    quote: 'They came through for us and we would recommend them to anyone',
    person: 'Felix Tsui',
    title: 'Project Manager, Cubic Health',
  },
  {
    logo: {
      file: {
        url: clickaiLogo,
      },
    },
    description: 'Clik.ai Logo',
    quote: 'Crowdlinker brought domain expertise and a fresh perspective',
    person: 'Parag Goswami',
    title: 'CEO and Co-founder, Clik.ai',
  },
  {
    logo: {
      file: {
        url: actoLogo,
      },
    },
    description: 'ACTO Logo',
    quote: 'We appreciate your team going above and beyond',
    person: 'Shannon Chen See',
    title: 'Content Marketing Coordinator at ACTO',
  },
]

export const pillarsData = [
  {
    hash: 'build-a-new-product',
    color: '#fff',
    linkColor: '#1F81E6',
    title: 'Build a new product',
    description: [
      'Building a brand new digital product from scratch can be a daunting task. Whether your digital product will be used by a few people or by millions of users across the globe, Crowdlinker has the strategy, design, development, and marketing capabilities to make your next product into an industry leader.',
    ],
    leftCol: {
      leftColTitle: 'Here is what you can expect from our engagement:',
      leftColContent: {
        list: {
          data: [
            'Run discovery to understand your needs',
            'Conduct market research to validate user needs ',
            'Build prototypes for usability testing',
            'Establish a prioritized product road map',
            'Build and market the product',
            'Provide product training before hand-off',
          ],
        },
      },
    },
    rightCol: {
      rightColTitle: 'View our projects',
      clients: [
        {
          name: 'Magnet',
          imageSrc: MagnetImage,
          url: 'https://www.magnetforensics.com/',
        },
        {
          name: 'Free Invoice Creator',
          imageSrc: FBImage,
          url: 'https://www.freeinvoicecreator.com/',
        },
        {
          name: 'Finaeo',
          imageSrc: FinaeoImage,
          url: 'https://www.finaeo.com/',
        },
        {
          name: 'Wildbrain',
          imageSrc: WildbrainImage,
          url: 'https://www.wildbrain.com/',
        },
      ],
      preCTATitle:
        'Build a unique product that is strategically targeted for growth',
    },
  },
  {
    hash: 'enhance-existing-experiences',
    color: '#D8D8D8',
    linkColor: '#E92778',
    title: 'Enhance existing experiences',
    description: [
      'Picking the right partner to improve your existing digital products is a significant decision. Companies big and small have trusted Crowdlinker in the past because of our keen ability to balance business goals, customer needs, and technical constraints. Our fresh perspective has helped transform digital products into category-leaders.',
    ],
    leftCol: {
      leftColTitle: 'Here is what you can expect from our engagement:',
      leftColContent: {
        list: {
          data: [
            'Audit existing user experience, tech stack, and industry',
            'Assess design, technology, and product-market fit',
            'Conduct product discovery and market research',
            'Create prototypes and test assumptions',
            'Build in-house or hand-off deliverables',
          ],
        },
      },
    },
    rightCol: {
      rightColTitle: 'View our projects',
      clients: [
        {
          name: 'League',
          imageSrc: LeagueImage,
          url: 'https://league.com/',
        },
        {
          name: 'Unilever',
          imageSrc: UnileverImage,
          url: 'https://www.vaseline.com/ca/en',
        },
        {
          name: 'Omstars',
          imageSrc: OmstarsImage,
          url: 'https://omstars.com/',
        },
        {
          name: 'FreshBooks Integrations',
          imageSrc: FBImage,
          url: 'https://www.freshbooks.com/integrations',
        },
      ],
      preCTATitle:
        'Refine your existing user experience, tech stack, and product strategy',
    },
  },
  {
    hash: 'disrupt-an-industry',
    color: '#fff',
    linkColor: '#76BC21',
    title: 'Disrupt an industry',
    description: [
      'Crowdlinker started off as a SaaS company that raised over $1 million in funding to build out a disruptive line-up of social media automation products. After pivoting into a full-service digital product studio, we continue to leverage our experience from our SaaS days to help founders take their product vision from concept to production.',
    ],
    leftCol: {
      leftColTitle: 'Here is what you can expect from our engagement:',
      leftColContent: {
        list: {
          data: [
            'Conduct industry, competitors, and risk assessment',
            'Understand the market and user needs ',
            'Create a product roadmap',
            'Design a brand that tells your story ',
            'Build functioning minimal viable product',
            'Launch with product-market fit',
          ],
        },
      },
    },
    rightCol: {
      rightColTitle: 'View our projects',
      clients: [
        {
          name: 'Sessionz',
          imageSrc: SessionzImage,
          url: 'https://www.sessionz.com/',
        },
        {
          name: 'Lance',
          imageSrc: LanceImage,
          url: 'https://lance.work/',
        },
      ],
      preCTATitle: 'Launch with flexibility, efficiency, and speed',
    },
  },
  {
    hash: 'product-advisory',
    color: '#D8D8D8',
    linkColor: '#8FA1A8',
    title: 'Product Advisory',
    description: [
      'Every forward-thinking company has the ambition to keep growing and to scale out their solution in order to help more people. Crowdlinker’s product consultancy acts as a catalyst for those companies to reach their desired goals faster. How much would your business benefit from a new perspective coming from experts who have built products used by millions of people daily?',
    ],
    leftCol: {
      leftColTitle:
        'Below are the various types of projects our clients are seeking:',
      leftColContent: {
        list: {
          data: [],
        },
        accordionContent: [
          {
            title: 'Create a comprehensive product plan',
            content:
              'We will work with you in establishing a product roadmap that is aligned with your business goals. The roadmap will be used to plan iterations and the future work of your organization',
          },
          {
            title: 'Data-backed recommendations',
            content:
              'We will present a report that highlights goals, defined strategy, market trends, and a go-to-market strategy for product launch',
          },
          {
            title: 'Innovative Technical Recommendations',
            content:
              'Whether you want to leverage existing technology or create a brand new tech stack, our team will make recommendations on what cutting-edge technologies and methodologies should be adopted',
          },
          {
            title: 'Product Market Fit and Risk Assessment',
            content:
              'Our team will undertake a comprehensive analysis of your current model and work with you on ensuring that is the best plan of action or suggest optimal alternatives',
          },
        ],
      },
    },
    rightCol: {
      rightColTitle: 'View our projects',
      clients: [
        {
          name: 'Netsweeper',
          imageSrc: NetsweeperImage,
          url: 'https://www.netsweeper.com/',
        },
        {
          name: 'Mercatus',
          imageSrc: MercatusImage,
          url: 'https://www.mercatus.com/',
        },
        {
          name: 'MLSE',
          imageSrc: MLSEImage,
          url: 'https://www.mlse.com/',
        },
        {
          name: 'Yumba',
          imageSrc: YumbaImage,
          url: 'https://www.yumba.ca/',
        },
      ],
      preCTATitle: 'Get a fresh perspective on your digital product strategy',
    },
  },
]
