import React from 'react'
import styled from '@emotion/styled'
import BackgroundImage from '../Shared/BackgroundImage/BackgroundImage'
import Container from '../Shared/Container/Container'
import Title from '../Shared/TitleWithStyledPeriod/TitleWithStyledPeriod'
import BackgroundDesktop from '../../images/linker-orange-desktop.svg'
import BackgroundTablet from '../../images/linker-orange-tablet.svg'
import BackgroundMobile from '../../images/linker-orange-mobile.svg'
import { pillarsData } from './data'

const Section = styled('div')`
  padding-top: 125px;
  padding-bottom: 125px;
  position: relative;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    padding-top: 80px;
    padding-bottom: 90px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 60px;
    padding-bottom: 64px;
  }
`

const StyledBackgroundImage = styled(BackgroundImage)`
  position: absolute;
  top: 172px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    top: 110px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    top: 106px;
  }
`

const StyledTitle = styled(Title)`
  max-width: 544px;
  color: #fff;

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 40px;
    line-height: 48px;
    margin-right: 0;
  }
`

const Text = styled('p')`
  font-size: 30px;
  line-height: 34px;
  font-weight: normal;
  max-width: 587px;
  display: flex;
  margin-left: auto;
  margin-top: 350px;
  font-family: ${props => props.theme.fonts.secondaryBook};
  color: #fff;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 18px;
    line-height: 20px;
    max-width: 351px;
    margin-top: 200px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    max-width: unset;
    margin-top: 110px;
    margin-left: 0;
  }

  br {
    margin-top: 40px;
  }
`

const Pillars = styled('div')`
  display: flex;
  flex-direction: row;
  margin-top: 67px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    flex-wrap: wrap;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    margin-top: 30px;
  }
`
const LinkWrapper = styled('div')`
  margin: 0 35px;

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    flex-basis: 50%;
    margin: 0 0 22px;
    padding: 0 20px;
    text-align: center;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    flex-basis: 100%;
    margin: 0 0 13px;
    padding: 0;
    text-align: left;
  }
`

const PillarLink = styled('a')`
  color: ${props => props.color};
  font-size: 24px;
  line-height: 1.25;
  font-family: ${props => props.theme.fonts.sourceCode};
  text-align: center;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-decoration: none;
  display: inline-block;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    background-color: ${props => props.color};
    height: 1px;
    transform: translateY(10px);
    display: block;
    visibility: hidden;
    transition: all 0.3s ease;
    opacity: 0;

    @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
      transform: translateY(5px);
    }
  }

  &:hover {
    &::after {
      visibility: visible;
      opacity: 1;
    }
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 16px;
  }

  @media screen and (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 16px;
    text-align: left;
  }
`

export default function WhatWeDo(props) {
  const { withLinks } = props

  return (
    <Section>
      <StyledBackgroundImage
        desktop={BackgroundDesktop}
        tablet={BackgroundTablet}
        mobile={BackgroundMobile}
      />
      <Container>
        <StyledTitle>What we do</StyledTitle>
        <Text>
          We work with companies who have big ambitions and are eager to lead
          change in their respective industries by helping them solve the most
          complex business challenges with design and technology.
          <br />
          <br />
          We consult clients with the following:
        </Text>
        {withLinks && (
          <Pillars>
            {pillarsData.map(({ title, linkColor, hash }, i) => (
              <LinkWrapper key={i}>
                <PillarLink href={`#${hash}`} color={linkColor}>
                  {title}
                </PillarLink>
              </LinkWrapper>
            ))}
          </Pillars>
        )}
      </Container>
    </Section>
  )
}

/**
 * withLinks set to false
 * in case if component parent doesn't have
 * a prop set and not show link
 */

WhatWeDo.defaultProps = {
  withLinks: false,
}
