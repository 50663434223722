import React, { useState } from 'react'
import styled from '@emotion/styled'
import Container from '../Shared/Container/Container'
import CTA from '../Buttons/TransparentCTA'
import ScrollableAnchor from 'react-scrollable-anchor'
import 'swiper/css/swiper.css'
import Swiper from 'react-id-swiper'
import Collapse from '@kunukn/react-collapse'
import PlusIcon from '../../images/services/plus-expand.svg'
import MinusIcon from '../../images/services/minus-collapse.svg'

const Wrapper = styled('div')`
  width: 100%;
  padding: 123px 0 153px;
  color: #000;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding: 81px 0 80px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding: 65px 0 40px;
  }
`

const Title = styled('h2')`
  font-family: ${props => props.theme.fonts.primaryMedium};
  font-weight: 500;
  font-size: 48px;
  line-height: 1.48;
  margin: 0 0 43px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 30px;
    margin: 0 0 38px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 24px;
    line-height: 1.25;
  }
`

const Desc = styled('p')`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 30px;
  line-height: 1.1;
  margin: 0 0 54px;
  max-width: 900px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 24px;
    max-width: 530px;
    margin: 0 0 38px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 20px;
    line-height: 1.1;
    margin: 0 0 35px;
  }
`

const TwoColumnWrapper = styled('div')`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    flex-direction: column;
  }
`

const LeftCol = styled('div')`
  flex: 1;
  padding-right: 88px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 100%;
    padding-right: 0;
  }
`

const RightCol = styled('div')`
  flex: 1;
  border-left: 1px solid #000;
  padding-left: 70px;
  max-width: 353px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding-left: 0;
    max-width: 100%;
    padding-right: 0;
    border-left: 0;
    border-top: 1px solid #000;
    padding-top: 41px;
    display: flex;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding-top: 33px;
    display: block;
  }
`

const RightColLeft = styled('div')`
  @media (max-width: ${props => props.theme.screenWidth.small}) {
    width: 100%;
  }
`

const RightColRight = styled('div')`
  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding-left: 47px;
    padding-top: 34px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 18px;
    margin: 0 0 33px;
    padding-left: 0;
    padding-top: 0;
  }
`

const ContentTitle = styled('h3')`
  font-family: ${props => props.theme.fonts.sourceCode};
  font-size: 24px;
  line-height: 1.25;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 0 34px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin: 0 0 23px;
    font-size: 16px;
    max-width: 420px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    margin: 0 0 20px;
  }
`

const Content = styled('div')`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 30px;
  line-height: 1.13;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 24px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 18px;
  }
`

const OrderedList = styled('ol')`
  margin: 0 0 30px;
  padding-left: 27px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    margin: 0 0 47px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    padding-left: 20px;
    margin: 0 0 33px;
  }

  li {
    margin: 0;
  }
`

const ClickableImage = styled('a')`
  display: block;
`

const ClientImage = styled('img')`
  max-width: 100%;
  height: auto;
`

const PreCTATitle = styled('h4')`
  font-family: ${props => props.theme.fonts.sourceCode};
  font-size: 18px;
  line-height: 24px;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin: 0 0 24px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    max-width: 214px;
    margin: 0 0 17px;
    font-size: 14px;
    line-height: 1.4;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 14px;
    line-height: 1.5;
  }
`

const CTAWrapper = styled('div')`
  max-width: 200px;
`

const ClientCarousel = styled('div')`
  display: flex;
  width: 100%;
  justify-content: center;
  margin: 0 0 20px;
  max-width: 284px;

  .swiper-wrapper {
    margin: 0 0 38px;
  }

  .swiper-pagination {
    span {
      background-color: #aeaeae !important;
      opacity: 1;

      &.swiper-pagination-bullet-active {
        background-color: #5f5f5f !important;
      }
    }
  }
`

const AccordionItem = styled('div')`
  padding: 20px 0;
  border-bottom: 1px solid #000;

  &:last-child {
    border-bottom: 0;
  }

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    padding: 15px 0;
  }
`

const AccordionTitle = styled('h3')`
  font-size: 20px;
  line-height: 1.45;
  font-family: ${props => props.theme.fonts.primaryRegular};
  cursor: pointer;
  font-weight: normal;
  position: relative;
  padding-right: 50px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 18px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 16px;
  }

  &::after {
    content: '';
    position: absolute;
    background-image: ${props =>
      !props.isOpen ? `url(${PlusIcon})` : `url(${MinusIcon})`};
    background-repeat: no-repeat;
    background-size: 100%;
    width: 16px;
    height: ${props => (!props.isOpen ? '16px' : '3px')};
    display: block;
    top: 50%;
    transform: translateY(-50%);
    right: 0px;
  }
`

const AccordionContent = styled(Collapse)`
  font-family: ${props => props.theme.fonts.secondary};
  font-size: 24px;
  line-height: 1.125;
  padding-right: 50px;

  @media (max-width: ${props => props.theme.screenWidth.large}) {
    font-size: 20px;
  }

  @media (max-width: ${props => props.theme.screenWidth.small}) {
    font-size: 18px;
  }
`

const AccordionContentWrap = styled('div')`
  padding-top: 20px;

  p {
    margin: 0 0 10px;
  }
`

const params = {
  autoplay: {
    delay: 3000,
  },
  speed: 800,
  loop: true,
  effect: 'slide',
  pagination: {
    el: '.swiper-pagination',
    clickable: true,
    dynamicBullets: true,
  },
}

const Carousel = ({ clients }) => (
  <ClientCarousel>
    <Swiper {...params}>
      {clients.map(({ name, imageSrc, url }) => (
        <ClickableImage
          key={name}
          href={url}
          target="_blank"
          rel="noopener noreferrer"
        >
          <ClientImage src={imageSrc} alt={name} />
        </ClickableImage>
      ))}
    </Swiper>
  </ClientCarousel>
)

const Accordion = ({ accordionContent = [] }) => {
  const [activeIndex, setActiveIndex] = useState(0)

  const onToggle = index => {
    if (activeIndex === index) {
      setActiveIndex(null)
    } else {
      setActiveIndex(index)
    }
  }
  return (
    <React.Fragment>
      {accordionContent.map(({ title, content }, i) => (
        <AccordionItem key={i}>
          <AccordionTitle
            onClick={() => onToggle(i)}
            isOpen={activeIndex === i}
          >
            {title}
          </AccordionTitle>
          <AccordionContent
            className="collapse-css-transition"
            isOpen={activeIndex === i}
          >
            <AccordionContentWrap>
              <p>{content}</p>
            </AccordionContentWrap>
          </AccordionContent>
        </AccordionItem>
      ))}
    </React.Fragment>
  )
}

export default function Pillar(props) {
  const { title, description, leftCol, rightCol, color, hash } = props.data
  const { leftColTitle, leftColContent } = leftCol
  const { list, accordionContent } = leftColContent
  const listItems = list.data
  const { rightColTitle, preCTATitle, clients } = rightCol

  return (
    <React.Fragment>
      <ScrollableAnchor id={hash}>
        <div />
      </ScrollableAnchor>
      <Wrapper>
        <Container>
          <Title>{title}</Title>
          {description.map((desc, i) => (
            <Desc key={i}>{desc}</Desc>
          ))}
          <TwoColumnWrapper>
            <LeftCol>
              <ContentTitle>{leftColTitle}</ContentTitle>
              <Content>
                <OrderedList>
                  {listItems.map((item, i) => (
                    <li key={i}>{item}</li>
                  ))}
                </OrderedList>
                {accordionContent && (
                  <Accordion accordionContent={accordionContent} />
                )}
              </Content>
            </LeftCol>
            <RightCol>
              <RightColLeft>
                <ContentTitle>{rightColTitle}</ContentTitle>
                <Carousel clients={clients} />
              </RightColLeft>
              <RightColRight>
                <PreCTATitle>{preCTATitle}</PreCTATitle>
                <CTAWrapper>
                  <CTA blackBorder />
                </CTAWrapper>
              </RightColRight>
            </RightCol>
          </TwoColumnWrapper>
        </Container>
      </Wrapper>
    </React.Fragment>
  )
}
