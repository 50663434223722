import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import Services from '../components/Services'
import StickyNav from '../components/Navigation/StickyNav'
import { graphql } from 'gatsby'

const metaTitle = 'What we do - Leading Digital Product Studio | Crowdlinker'

const metaDescription =
  'Crowdlinker is an award-winning digital product studio. We help companies build new digital products, enhance existing experiences, disrupt industries, and advise on product development.'

const ServicesPage = props => {
  const { siteUrl } = props.data.site.siteMetadata
  const { path } = props
  const pageURL = siteUrl + path

  return (
    <Layout>
      <Helmet>
        <title>{metaTitle}</title>
        <meta name="description" content={metaDescription} />
        <meta property="og:url" content={pageURL} />
        <meta property="og:description" content={metaDescription} />
        <meta name="twitter:title" content={metaTitle} />
        <meta name="twitter:description" content={metaDescription} />
      </Helmet>
      <StickyNav />
      <Services />
    </Layout>
  )
}

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`

export default ServicesPage
